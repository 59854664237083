const axios =  require("axios");
 
 const API_VERSION = 'v15.0';
const page_id = '201002076727199';


const getHistory = async () => {

    let fbPostsWidget = window.document.getElementById('fbPostsWidget');

    if(fbPostsWidget){

        
    const historyResponse = await axios.get(`https://graph.facebook.com/${API_VERSION}/petrgatinaismontargois/feed`,{
        params:{
            access_token: "EABYxpTKQc6kBAOsIMjqxbYZBqonCBq8qs4ZAjhEwllzYsdXM9DLyHnYxvfKo3tmZAoZAhmY2kfdwJfApgZAWVtdzeArQHASOT4whytKGggBVxZBobCyxJ7gXkjaMq8Y3XtVgyTjwlClFzac5iaOAYX7tFV8VkwXF3LPcbtZAqDt5t1wVpSL0zCo",
            fields: "permalink_url",
        },
    });
      
    console.log(historyResponse.data);


    



    for(let i=0; i<3; i++){
    
        var post = historyResponse.data.data[i];
   
            // const embedCode = `<iframe id="${post.id}" src="https://www.facebook.com/plugins/post.php?href=${post.permalink_url}&show_text=true&appId=911749112232090 " width="100%"  height="100%" style="border:none; " scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>`
           
            let embedCode = `<div class="col-12 px-0 text-center mb-5 fb-post" data-lazy="true" data-width="750" data-show-text="true" data-href="${post.permalink_url}">
            ${post.permalink_url}
            </div>`;
            
            var existing = "";
            if(i !== 0){
                existing =  fbPostsWidget.innerHTML;
            } 
    
            fbPostsWidget.innerHTML = existing + embedCode;

        
    }

    }


}


// window.fbAsyncInit()

window.fbAsyncInit = async function() {
    //xfbml=1&version=v1.0&appId=6247035075326889&autoLogAppEvents=1
    FB.init({
      appId            : '6247035075326889',
      autoLogAppEvents : true,
      xfbml            : true,
      version          : 'v16.0'
    });
    // my code
    await getHistory();


    FB.XFBML.parse( window.document.getElementById('fbPostsWidget') );
  };


// 
window.fbAsyncInit();